var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showDeleteModal },
      staticStyle: { "z-index": "1005 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [_vm._v("Delete Zone")]),
          _c("button", {
            staticClass: "delete",
            attrs: { "aria-label": "close" },
            on: { click: _vm.toggleClose },
          }),
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _vm._v(
            " Are you sure you want to delete this Zone(" +
              _vm._s(_vm.selectedZone.value) +
              ")? "
          ),
        ]),
        _c(
          "footer",
          { staticClass: "modal-card-foot is-justify-content-flex-end" },
          [
            _c(
              "button",
              {
                class: _vm.isDeleting
                  ? _vm.deletingButtonLoading
                  : _vm.deletingButtonNotLoading,
                on: { click: _vm.deleteZone },
              },
              [_vm._v(" Confirm ")]
            ),
            _c(
              "button",
              {
                staticClass: "button is-small level-item is-rounded",
                on: { click: _vm.toggleClose },
              },
              [_vm._v(" Close ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }