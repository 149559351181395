<template>
  <div
    class="modal"
    style="z-index: 1005 !important;"
     v-bind:class="{ 'is-active': showDeleteModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Delete Zone</p>
        <button class="delete" aria-label="close" @click="toggleClose"></button>
      </header>
      <section class="modal-card-body">
        Are you sure you want to delete this Zone({{selectedZone.value}})?
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          @click="deleteZone"
          v-bind:class="
            isDeleting ? deletingButtonLoading : deletingButtonNotLoading
          "
        >
          Confirm
        </button>
        <button
          class="button is-small level-item is-rounded"
          @click="toggleClose"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { deleteZoneInfo } from '@/store/fireStore'
import { getSelectedZone } from '@/store/mongoStore'

export default {
  data () {
    return {
      isDeleting: false,
      deletingButtonLoading:
        'button is-small is-info level-item is-rounded is-loading',
      deletingButtonNotLoading:
        'button is-small is-info level-item is-rounded',
    }
  },
  props: {
    showDeleteModal: {
      type: Boolean
    },
    selectedZone: {
      type: Object
    }
  },
  methods: {
    toggleClose () {
      this.$emit('closeModal')
    },
    async deleteZone () {
      this.isDeleting = true
      try {
        const deleted = await deleteZoneInfo(this.selectedZone.id)
        if (deleted) {
          this.toggleClose()
          this.isDeleting = false
          this.$notify({
            type: 'success',
            group: 'zones',
            title: 'Zone Delete Successfully'
          })
        } else {
          this.isDeleting = false
          this.$notify({
            type: 'error',
            group: 'zones',
            title: 'Zone is still in use'
          })
        }
      } catch (e) {
        this.isDeleting = false
        this.$notify({
          type: 'error',
          group: 'zones',
          title: 'There was an error, please try again'
        })
      }
      this.isDeleting = false
    }
  }
}
</script>
<style>
</style>
